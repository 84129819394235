import { useEffect, useState, type FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ClientMid from '../../middlewares/ClientMid';
import { GetProfileResponseData } from '../../grpc/client/client_general_pb';
import { GetDetailUserPackageRequest, GetDetailUserPackageResponseData, GetUserPackageStatusResponseData } from '../../grpc/client/client_user_package_pb';
import { clientUserPackage } from '../../App';
import { getToken, money } from '../../utils/helper';
import { errorToast } from '../../utils/helper-ui';
import Back from '../../components/back';
import { CANCELED, DECLINED, FAILED, PAID, PENDING_CONFIRMATION } from '../../utils/contants';
import { Timeline } from 'rsuite';
import CheckIcon from '@rsuite/icons/legacy/Check';

interface PackageOrderDetailProps { }

const PackageOrderDetail: FC<PackageOrderDetailProps> = ({ }) => {
    const nav = useNavigate()
    const { userPackageId } = useParams()
    const [profile, setProfile] = useState<GetProfileResponseData.AsObject>();
    const [userPackage, setUserPackage] = useState<GetUserPackageStatusResponseData.AsObject>();
    const [mounted, setMounted] = useState(false);
    const [data, setData] = useState<GetDetailUserPackageResponseData.AsObject | null | undefined>(null);


    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted && !userPackageId) return

        getDetail()
    }, [mounted]);

    const getDetail = async () => {
        let req = new GetDetailUserPackageRequest()
        req.setId(userPackageId!)
        clientUserPackage.getDetailUserPackage(req, getToken(), (err, resp) => {
            if (err) {
                errorToast(err.message)
                return
            }
            setData(resp.getData()?.toObject())
        })
    }

    return (<ClientMid onLoad={setUserPackage} onLoadProfile={setProfile} noPadding={true} hideMenu header={
        <div className='w-full px-4 py-2 justify-between items-center flex pt-6 bg-white'>
            <Back target='/package-order' />
            <h6>Detail Pesanan </h6>
            <div className='p-2 '></div>
        </div>}>

        {(data?.paymentStatus == PAID || data?.paymentStatus == PENDING_CONFIRMATION) && <>
            <div className='w-full  bg-slate-200 pt-9 pb-12 px-6 flex justify-between text-white items-center' style={{ backgroundImage: 'url(/assets/bg.png)', backgroundRepeat: 'no-repeat', backgroundPositionX: 0, backgroundPositionY: -100, backgroundSize: 'cover' }}>
                <div>
                    <h5 className='mb-2'>
                        {data?.paymentStatus == PAID && "Pembayaran Berhasil!"}
                        {data?.paymentStatus == PENDING_CONFIRMATION && "Pesanan sedang diproses"}
                    </h5>
                    <p className=' text-xs'>
                        {data?.paymentStatus == PAID && `Pesanan telah berhasil status akun saat ini Premium selama 30 hari ke depan`}
                        {data?.paymentStatus == PENDING_CONFIRMATION && `Mohon tunggu beberapa saat sampai pembayaran berhasil dikonfirmasi`}
                    </p>
                </div>
                <div className='ml-4'>
                    <img src={data?.paymentStatus == PAID ? `/assets/payment_paid.png` : `/assets/payment_pending.png`} alt="" className='w-10' />
                </div>
            </div>
            <div className=' rounded-t-xl -mt-6 bg-white mb-1'>
                <div className='p-4 border-b border-b-gray-100'>
                    <h5>Status Pembayaran</h5>
                </div>
                <div className='p-4 border-b border-b-gray-100'>
                    <Timeline className="custom-timeline">
                        <Timeline.Item dot={<CheckIcon style={{ background: 'rgb(14,165,233)', color: '#fff' }} />}>
                            <p>Pembayaran selesai</p>
                        </Timeline.Item>
                        <Timeline.Item dot={<CheckIcon style={data?.paymentStatus == PAID ? { background: 'rgb(14,165,233)', color: '#fff' } : {}} />}>
                            <p>Pembayaran dikonfirmasi</p>
                        </Timeline.Item>
                        <Timeline.Item dot={<CheckIcon style={data?.paymentStatus == PAID ? { background: 'rgb(14,165,233)', color: '#fff' } : {}} />}>
                            <p>Pesanan berhasil</p>
                        </Timeline.Item>
                    </Timeline>
                </div>
            </div>
        </>
        }
        {(data?.paymentStatus == CANCELED || data?.paymentStatus == DECLINED || data?.paymentStatus == FAILED) &&
            <div className='p-4 mb-2 bg-white text-center'>
                <div className=' text-red-500 flex items-center justify-center mb-2'>
                    <img src="/assets/alert.png" alt="" className='w-7 mr-2' />
                    <h5>Pesanan Batal</h5>
                </div>
                <p>Pesanan gagal dikarenakan {data?.paymentStatusRemarks}</p>
            </div>}
        <div className=' bg-white mb-1'>
            <div className='p-4 border-b border-b-gray-100'>
                <h5>Detail Pesanan</h5>
            </div>
            <div className='p-4 border-b border-b-gray-100'>
                <div className='flex justify-between py-2'>
                    <span className=''>Komentar per hari</span>
                    <span>{money(data?.commentPerDay)} / Hari</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=''>Jumlah Hari</span>
                    <span>{money(30)}</span>
                </div>
                <div className='flex justify-between py-2 border-t'>
                    <span className=' font-bold'>Total Komentar</span>
                    <span>{money(data?.totalComment)}</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=' text-sky-500'>+ Bonus Likes</span>
                    <span className='text-sky-500'>{money(data?.totalLikes)}</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=' text-sky-500'>+ Bonus Shares</span>
                    <span className='text-sky-500'>{money(data?.totalShares)}</span>
                </div>
                <div className='flex justify-between py-2 border-b'>
                    <span className=' font-bold'>Harga per Komentar</span>
                    <span className=' font-semibold'>Rp. {money(data?.pricePerComment)}</span>
                </div>
                <div className='flex justify-between py-2'>
                    <span className=' font-bold'>Total</span>
                    <span className=' font-semibold'>Rp. {money(data?.totalPrice)}</span>
                </div>
            </div>
        </div>
        <div className='p-4'>
            <button onClick={() => nav("/package-order")} type="button" className="btn w-full ">Kembali</button>
            
        </div>
        { data?.paymentStatus == "pending_payment" &&

        <div className='px-4'>
            <button onClick={() => nav(`/user-package-confirmation/${userPackageId}`)} type="button" className="btn-orange w-full ">Bayar Sekarang</button>

        </div>
        }
    </ClientMid>
    );
}
export default PackageOrderDetail;